import * as React from "react";
import { FullScreenDialog, FullScreenDialogProps } from "../../FullScreenDialog";
import { Mutation, MutationCreateFileArgs, MutationDeleteFileArgs, Query, QueryGetFilesArgs } from "../../../generated/nest-graphql";
import { GET_FILES } from "../../../graphql/queries/getFiles";
import { CREATE_FILE } from "../../../graphql/mutations/createFile";
import { prop } from "ramda";
import { Button, Paper, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { FileFormValues } from "../../Forms/FileForm";
import { FormikHelpers } from "formik";
import { showSuccessAlert } from "../../../actions";
import { useDispatch } from "../../../contexts/snackbar-context";
import { useMutation, useQuery } from "@apollo/client";
import { UploadPhotoSection } from "./UploadPhotoSection";
import { DeleteFile } from "../../../graphql/queries/deleteFile";

export const PhotoDialog: React.FC<
  FullScreenDialogProps & { contactId: string; name: string, fieldName: string, fileCategory?: string; jobId?: string, optionsLabel?: string[] }
> = ({ onClose, open, title, contactId, fileCategory, jobId, name, fieldName, optionsLabel }) => {
  const dispatch = useDispatch();
  const initialValues = {
    caption: "",
    fileName: "",
    url: "",
    files: [],
  };
  const [createFile] = useMutation<Mutation, MutationCreateFileArgs>(CREATE_FILE);
  const onSubmit = async (values, helper: FormikHelpers<FileFormValues>) => {
    await createFile({
      variables: {
        createFileInput: {
          contact: contactId,
          path: `contacts/${contactId}/${values.fileName}`,
          url: values.url,
          share: false,
          fileName: `${values.fileName}`,
          caption: values.caption,
          category: values.fileCategory,
          jobId: jobId,
        },
      },
      update: (proxy, { data: { createFile } }) => {
        proxy.writeQuery({
          query: GET_FILES,
          variables: { contactId },
          data: {
            getFiles: createFile,
          },
        });
      },
    });
    showSuccessAlert(dispatch, "Success");
    helper.resetForm({
      values: initialValues,
      isSubmitting: false,
    });
    await helper.validateForm(initialValues);
  };
  const { data, startPolling, stopPolling, refetch } = useQuery<Query, QueryGetFilesArgs>(GET_FILES, {
    variables: {
      contactId,
    },
    returnPartialData: true,
    fetchPolicy: "cache-and-network",
  });

  const files = prop("getFiles", data);
  const filesCount = files?.length;

  React.useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  React.useEffect(() => {
    if (open && filesCount) {
      startPolling(1000 * 300); // 300s = 5min (Signed urls expire time)
    } else {
      stopPolling();
    }
  }, [open, filesCount]);

  React.useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  return (
    <FullScreenDialog title={title} open={open} onClose={onClose}>
      <Paper className="py-4">
        <Container>
          {/*!!prop("length", files) && <ShowFiles files={files} />*/}
          <UploadPhotoSection contactId={contactId} jobId={jobId} name={name} onSubmit={onSubmit} initialValues={initialValues} fieldName={fieldName} optionsLabel={optionsLabel} onClose={onClose} />
        </Container>
      </Paper>
    </FullScreenDialog>
  );
};

export type FileResult = {
    fileName: string,
    signedUrl: string,
    url: string,
    caption: string,
    catefory?: string,
    jobId: string,
}

export const PhotoDialogView: React.FC<FullScreenDialogProps & { files: any, contactId: string, onDelete: ()=>void }> = ({ onClose, open, title, files, contactId, onDelete}) => {

    const [deleteFile] = useMutation<Mutation, MutationDeleteFileArgs>(DeleteFile);

    return (
        <FullScreenDialog title={title} open={open} onClose={onClose}>
          <Paper className="py-4">
            <Container>
              {/*!!prop("length", files) && <ShowFiles files={files} />*/}
              <div className="flex flex-col gap-2">
              {files.map(file=> (
                <div className="my-4">
                    <Typography>{`${file.caption.split('-')[1]} ${file.caption.split('-')[2]}`}</Typography>
                    <img src={file.signedUrl} alt={file.caption} className="mt-2" />
                    <Button
                        size={"large"}
                        type={'button'}
                        variant={"contained"}
                        fullWidth={true}
                        className="mt-8"
                        color={"secondary"}
                        onClick={async ()=>{
                            await deleteFile({
                                variables: {
                                  contact: contactId,
                                  fileName: file.fileName,
                                },
                              });
                            if(onDelete){
                                onDelete();
                            }
                        }}
                        //startIcon={<Cancel />}
                        >
                        Delete Photo
                    </Button>
                </div>
              ))}
              </div>
            </Container>
          </Paper>
        </FullScreenDialog>
      );
}
