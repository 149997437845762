import * as React from "react";
import { Form, Formik } from "formik";
import { Button, List, ListItemSecondaryAction, ListItemText, Paper, Typography } from "@material-ui/core";
import { ContactListItem } from "../ListItems/ContactListItem";
import { AppointmentListItem } from "../ListItems/AppointmentListItem";
import { ServiceLocationListItem } from "../ListItems/ServiceLocationListItem";
import { defaultTo, equals, prop, anyPass, isEmpty, isNil, startsWith, uniqBy, reject, pluck } from "ramda";
import ListItem from "@material-ui/core/ListItem";
import { ListItemWithSubList } from "../ListItems/ListItemWithSubList";
import { LinkListItem } from "../ListItems/LinkListItem";
import { VehicleInfoListItem } from "../ListItems/VehicleInfoListItem";
import { ItemsField } from "./fields/ItemsField";
import { FilesListItem } from "../ListItems/FilesListItem";
import {
  Appointment,
  Contact,
  Item,
  JobFeedback,
  PartsStore,
  Query,
  PromoCode,
  ViocStore,
  QueryGetVehicleArgs,
  Job,
} from "../../generated/nest-graphql";
import { VehicleInfoValues } from "../../types/VehicleInfoValues";
import * as Yup from "yup";
import { TextAreaField } from "./fields/TextAreaField";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import { BottomSubmitButtonRow } from "../Buttons/BottomSubmitButtonRow";
import { PartsInfoListItem } from "../ListItems/PartsInfoListItem";
import { VehicleInfoSymptomsFormSection } from "./VehicleInfoSymptomsFormSection";
import { JobTypeListItem } from "../ListItems/JobTypeListItem";
import { flow, pipe } from "fp-ts/lib/function";
import { PartsStoreListItem } from "../ListItems/PartsStoreListItem";
import { useQuery } from "@apollo/client";
import { TECHNICIAN_GET_ME } from "../../graphql/queries/technicianGetMe";
import { PartsOrderingSurveyFormValues } from "./Surveys/PartsOrderingSurveyForm";
import { ServicesSection } from "../services/ServicesSection";
import { JOB, SERVICE_CATALOGUE_USED_ITEMS, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import { PreJobCheckListFormValues } from "../Jobs/PreJobCheckListDialog";
import { JobViocStoreSelectField } from "./fields/JobViocStoreSelectField";
import { PreJobCheckListV2FormValues } from "../PreJobChecklistV2/PreJobCheckListV2Dialog";
import { GET_VEHICLE } from "../../graphql/queries/getVehicles";
import { showErrorAlert, showSuccessAlert } from "../../actions";
import { nestBackendClient } from "../../lib/nestBackendClient";
import { useDispatch } from "../../contexts/snackbar-context";
import { groupServicesByGrade } from "../PreJobChecklistV2/colorGradeHelpers";

export type EJIItemFormValue = {
  id?: string;
  serviceProductId: string;
  productSelection?: {
    name?: string;
    part?: {
      id?: string;
      partId?: number;
      productName?: string;
      label?: string;
      vendorUnitCost?: number;
      type?: string;
    };
  };
  type: string;
  units?: number;
  overriddenCustomerPrice: boolean;
  customerPrice: number;
  vendorUnitCost?: number;
  behavior: string;
  category: string;
  notes?: string;
  orderItem?: {
    partsStore?: PartsStore;
    isOrdered?: Boolean;
    partNumber?: String;
  };
  marketVendorUnitCostMultiplierUsed?: number;
  marketLaborRateUsed?: number;
};

export type PartsCalcs = {
  marketVendorUnitCostMultiplierUsed?: number;
  overriddenCustomerPrice?: boolean;
  totalPrice?: string;
  vendorCost?: string;
};

export type EJIServiceFormValue = {
  id?: string;
  service: string;
  name: string;
  inEstimate: boolean;
  customerPrice: number;
  technicianPay: number;
  partsCalcs: PartsCalcs;
  items: EJIItemFormValue[];
  updatedAt?: Date;
  createdAt?: Date;
  inInvoice?: boolean;
  needsRecalculation?: boolean;
};

export type EJIDiscountFormValue = {
  type: string;
  amount: number;
  reason: string;
  total?: number;
};

export type PromoCodeFormValue = {
  code: string;
  name: string;
  coupon: {
    name: string;
    valid: boolean;
    discount: EJIDiscountFormValue;
  };
};

export type EJIVendorUnitCostMultiplierFormValue = {
  startRangePrice?: number;
  endRangePrice?: number;
  multiplier?: number;
};

export type EJIPricingConfigFormValue = {
  laborRate?: number;
  vendorUnitCostMultipliers?: EJIVendorUnitCostMultiplierFormValue[];
};

export type EJIPriceInfoFormValue = {
  pricingConfig?: EJIPricingConfigFormValue;
  partsTotal?: number;
  laborTotal?: number;
  feesTotal?: number;
  subTotal?: number;
  discounts?: EJIDiscountFormValue[];
  promoCodes?: PromoCode[];
  discountTotal?: number;
  finalPartsTotal?: number;
  finalLaborTotal?: number;
  finalFeesTotal?: number;
  finalSubTotal?: number;
  partsTax?: number;
  laborTax?: number;
  totalTax?: number;
  amountDue?: number;
};

export type JobDetailsFormValues = VehicleInfoValues &
  JobFeedback & {
    partsOrderingIssuesSurvey?: PartsOrderingSurveyFormValues;
    partsOrderingIssuesSurveys: PartsOrderingSurveyFormValues[];
    contact: Contact;
    market: string;
    taxable: boolean;
    estimate?: string;
    serviceLocation: string;
    status: string;
    type: string;
    serviceCallReason: string;
    serviceCallReasonExtraInfo: string;
    warrantyCheckReason: string;
    warrantyCheckReasonExtraInfo: string;
    warrantyCheckTechReason: string;
    warrantyCheckTechReasonExtraInfo: string;
    appointmentId: string;
    jobName: string;
    vehicleId: string;
    privateNotes?: string;
    serviceLocationNotes?: string;
    description?: string;
    viocStore?: ViocStore;
    items: Item[];
    services: EJIServiceFormValue[];
    discounts?: EJIDiscountFormValue[];
    promoCodes?: PromoCodeFormValue[];
    priceInfo?: EJIPriceInfoFormValue;
    serviceCatalogueUsed?: string;
    partsOrderNumber?: string;
    partsLocation?: string;
    partsNotes?: string;
    partsOrdered?: boolean;
    preJobCheckList?: PreJobCheckListFormValues;
    preJobCheckListV2?: PreJobCheckListV2FormValues;
    locationType?: string;
  };

const jobDetailsFormValidationSchema = Yup.object().shape({
  status: Yup.string().required("Required"),
  contact: Yup.object().required("Required"),
  serviceLocation: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  serviceCallReason: Yup.string().when("type", {
    is: flow(defaultTo(""), startsWith("Service")),
    then: Yup.string().required("Must Provide a Service Call Reason"),
    otherwise: Yup.string().nullable(),
  }),
  serviceCallReasonExtraInfo: Yup.string().when(["type", "serviceCallReason"], {
    is: (type, reason) =>
      flow(defaultTo(""), startsWith("Service"))(type) && flow(defaultTo(""), startsWith("Other"))(reason),
    then: Yup.string().required("Must Provide Additional Details"),
    otherwise: Yup.string().nullable(),
  }),
  warrantyCheckTechReason: Yup.string().when("type", {
    is: flow(defaultTo(""), startsWith("Warranty Check")),
    then: Yup.string().required("Must Provide a Warranty Check Reason"),
    otherwise: Yup.string().nullable(),
  }),
  warrantyCheckTechReasonExtraInfo: Yup.string().when(["type", "warrantyCheckTechReason"], {
    is: (type, reason) =>
      flow(defaultTo(""), startsWith("Warranty Check"))(type) && flow(defaultTo(""), startsWith("Other"))(reason),
    then: Yup.string().required("Must Provide Additional Details"),
    otherwise: Yup.string().nullable(),
  }),
  jobName: Yup.string().required("Required"),
  services: Yup.array().of(
    Yup.object().shape({
      items: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required(),
          productSelection: Yup.object().when("category", {
            is: equals("Part"),
            then: Yup.object()
              .shape({
                name: Yup.string().required(),
              })
              .required(),
            otherwise: Yup.object()
              .shape({
                name: Yup.string().notRequired(),
              })
              .nullable()
              .notRequired(),
          }),
          orderItem: Yup.object().when("category", {
            is: equals("Part"),
            then: Yup.object()
              .shape({
                partNumber: Yup.string().nullable().notRequired(),
              })
              .required(),
            otherwise: Yup.object().nullable().notRequired(),
          }),
          customerPrice: Yup.string().required(),
          vendorUnitCost: Yup.string()
            .nullable()
            .when("category", { is: (val) => val === "Part", then: Yup.string().required() }),
        })
      ),
    })
  ),
});

type JobDetailsFormProps = {
  initialValues: JobDetailsFormValues;
  job?: Job;
  onSubmit: (values: any, helpers: any) => void;
  appointment: Appointment;
  fileNames: string[];
  jobId: any;
  toggleSelectedServices?: (index: number) => void;
};

export const JobDetailsForm: React.FC<JobDetailsFormProps> = ({
  initialValues,
  onSubmit,
  appointment,
  fileNames,
  jobId,
  job,
  toggleSelectedServices,
}) => {
  const { data: technicianGetMeData } = useQuery<Query>(TECHNICIAN_GET_ME);

  const { data, loading } = useQuery<Query, QueryGetVehicleArgs>(GET_VEHICLE, {
    variables: {
      id: initialValues.vehicleId,
    },
  });

  const [sendingLink, setSendingLink] = React.useState(false);

  const dispatch = useDispatch();

  const sendDIgitalInspection = async () => {
    setSendingLink(true);
    try {
      const response = await nestBackendClient.post("job-appointments/send-digital-inspection", {
        jobId,
        contact: data.getJob.contact.id,
        phoneNumber: data.getJob.contact.phoneNumber,
      });
      showSuccessAlert(dispatch, "Inspection sended successfully");
    } catch (error) {
      showErrorAlert(dispatch, error.message);
    }
    setSendingLink(false);
  };

  const { soon, immediatly } = groupServicesByGrade(job);

  return (
    <Formik<JobDetailsFormValues>
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={jobDetailsFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, setFieldValue, values, submitForm, setValues, touched }) => {
        const {
          contact,
          description,
          jobName,
          serviceLocation,
          serviceLocationNotes,
          type,
          vehicleId,
          customerExpectation,
          items,
          services,
          partsOrderNumber,
          partsLocation,
          partsNotes,
          locationType,
        } = values;

        const dirty = !!Object.keys(touched)
          .map((key) => ({ key, value: touched[key] }))
          .filter(({ key, value }) => key !== "services" && value).length;

        const wheelTorque = !loading && data?.getVehicle?.vehicleTechnicalInfo?.wheelTorque;

        const computedLocationNotes = locationType
          ? `Location Type: ${locationType}\n${serviceLocationNotes || ""}`
          : serviceLocationNotes;
        const partsStores = pipe(
          pluck("partsStore", items),
          reject(anyPass([isEmpty, isNil])) as () => PartsStore[],
          uniqBy(prop("id"))
        );
        const contactId: string = prop("id", contact);
        return (
          <Form
            // padding bottom to offset save button
            className="pb-4"
          >
            <Paper className={"pb-3"}>
              {values.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES && (
                <ServicesSection
                  id={jobId}
                  services={services}
                  values={values}
                  setValues={setValues}
                  stage={JOB}
                  parentSetFieldValue={setFieldValue}
                  parentSubmitForm={submitForm}
                  parentSubmitting={isSubmitting}
                  initialValues={initialValues}
                  toggleSelectedServices={toggleSelectedServices}
                />
              )}
              <List>
                {!!values.preJobCheckListV2 && (
                  <ListItem divider>
                    <ListItemText
                      primary="Recommended Services"
                      secondary={
                        <ul className="list-disc list-inside">
                          <br/>
                          <br/>
                          <br/>
                          {!!immediatly?.length ? (
                            <li>Service Recommended: {immediatly.map((s) => s.config.title).join(", ")}</li>
                          ) : null}
                          {!!soon?.length ? <li>Service Soon: {soon.map((s) => s.config.title).join(", ")}</li> : null}
                        </ul>
                      }
                    />
                    <ListItemSecondaryAction style={{marginRight: 50, marginBottom: 20}}>
                      <Button
                        color={"primary"}
                        variant={"outlined"}
                        disabled={sendingLink}
                        onClick={sendDIgitalInspection}
                      >
                        Send Inspection Report Link
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
                <ContactListItem contact={values.contact} />
                {appointment && <AppointmentListItem appointment={appointment} />}
                <ServiceLocationListItem address={serviceLocation} notes={computedLocationNotes} />
                <LinkListItem divider={true} title="Job History" to={`/jobs-history/${contactId}`} />
                <JobTypeListItem
                  jobType={type}
                  parentSubmitForm={submitForm}
                  parentSubmitting={isSubmitting}
                  isValid={isValid && dirty}
                  values={values}
                />
                {type === "Vioc Inspection" ? (
                  <JobViocStoreSelectField name={"viocStore"} label="Vioc Store" className="px-4 mt-4" />
                ) : null}
                <ListItemWithSubList divider={false} title={"Job Name"} secondaries={[jobName]} />
                <ListItemWithSubList divider={true} title={"Description"} secondaries={[description]} />
                <VehicleInfoListItem vehicleId={vehicleId} contactId={contactId} />
                {wheelTorque && (
                  <ListItem divider style={{ paddingRight: 90 }}>
                    <ListItemText primary={`Wheel torque`} secondary={<>{wheelTorque}</>} />
                  </ListItem>
                )}
                {/*<VehicleInfoSymptomsFormSection />*/}
                {customerExpectation && (
                  <ListItemWithSubList
                    divider={true}
                    title={"Customer Expectation for Repairs"}
                    secondaries={[customerExpectation]}
                  />
                )}
                {values.partsOrderNumber && (
                  <PartsInfoListItem
                    partsOrderNumber={partsOrderNumber}
                    partsLocation={partsLocation}
                    partsNotes={partsNotes}
                  />
                )}
                {!isEmpty(partsStores) && (
                  <>
                    <ListItem divider={false}>
                      <ListItemText
                        primary={<Typography style={{ fontWeight: "bold" }}>Parts Store(s)</Typography>}
                        secondary={partsStores.map((partsStore: any, idx: string | number | undefined) => {
                          return (
                            <PartsStoreListItem
                              key={idx}
                              partsStore={partsStore}
                              isHomePartsStore={equals(
                                (partsStore as any)?.id,
                                technicianGetMeData?.technicianGetMe?.homePartsStore?.id
                              )}
                            />
                          );
                        })}
                      />
                    </ListItem>
                  </>
                )}
                {values.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_ITEMS && (
                  <>
                    <Container>
                      <div className="grid gap-4 pt-2">
                        <ItemsField
                          additionalPartsInfoRenderer={({ partsStore }) => {
                            if (partsStore) {
                              const homePartsStore = technicianGetMeData?.technicianGetMe?.homePartsStore;
                              return !homePartsStore || equals(partsStore.id, homePartsStore?.id) ? (
                                <Typography component={"span"} className={"block"} variant={"subtitle1"}>
                                  {partsStore.vendor} {partsStore.name}
                                </Typography>
                              ) : (
                                <Typography
                                  component={"span"}
                                  className={"block"}
                                  variant={"subtitle1"}
                                  style={{ color: "#f04e23" }}
                                >
                                  {partsStore.vendor} {partsStore.name}
                                </Typography>
                              );
                            }
                          }}
                          parentSubmit={submitForm}
                          setFieldValue={setFieldValue}
                          name={"items"}
                          editable={false}
                        />
                      </div>
                    </Container>
                    <Divider />
                  </>
                )}

                <ListItem divider={true}>
                  <TextAreaField name="privateNotes" label={"Private Notes"} />
                </ListItem>
                <FilesListItem divider={false} contactId={contactId} numFiles={fileNames.length} />
              </List>
            </Paper>
            <BottomSubmitButtonRow label={"Update Job"} isValid={isValid && dirty} isSubmitting={isSubmitting} />
          </Form>
        );
      }}
    </Formik>
  );
};
