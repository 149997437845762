import React, { useState } from "react";
import { FormItems } from "./forms";
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton } from "@material-ui/core";
import { CameraAlt, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { FieldGenerator } from "./FieldGenerator";
import { PhotoDialog, PhotoDialogView } from "../../Files/photos/PhotoDialog";
import { useHistory } from "react-router-dom";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useQuery } from "@apollo/client";
import { Query } from "@testing-library/react";
import { QueryGetFilesArgs } from "../../../generated/nest-graphql";
import { GET_FILES } from "../../../graphql/queries/getFiles";

type Props = {
  form: FormItems;
};

export const FormGenerator = ({ form }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const router = useHistory();
  const uiv = (new URLSearchParams(router.location.search)).get('uiv');
  const isPhotoMode = uiv === 'photo';

  const withPhoto = ['frontPads', 'frontRotors', 'rearPads', 'rearRotors', 'brakeFluid', 'rearShoes', 'rearDrums']

  return (
    <>
      {form.map((item, idx) => {
        if (item.itemType === "section") {
          return (
            <div className="grid grid-cols-1 gap-4 p-4 border-[1px] rounded-lg mb-4" key={idx}>
              <div className="flex flex-row items-center mb-4 col-span-full">
                {item.title && <h2 className="text-lg">{item.title}</h2>}
                {item.required && (
                  <>
                    <div className="ml-1">Required</div>
                    <div className="ml-4 text-[#D50000]">*</div>
                  </>
                )}
              </div>
              {item.subTitle && <h3 className="col-span-full">{item.subTitle}</h3>}
              {item.children?.length && <FormGenerator form={item.children as FormItems} />}
              {item.children?.length && <div className="col-span-full">{isPhotoMode && withPhoto.includes(item.code) && <FileInputDialog name={item['title'] || item.subTitle} jobId={item['jobId']} contactId={item['contactId']} field={item.code} />}</div>}
              {item.description && <div className="col-span-full">{item.description}</div>}
            </div>
          );
        } else if (item.itemType === "drawer") {
          return (
            <Accordion
              key={idx}
              style={{
                borderRadius: 12,
                border: "1px solid #E5E5E5",
                marginBottom: 16,
              }}
              expanded={isExpanded}
            >
              <AccordionSummary
                className="flex items-center justify-center"
                onClick={() => setIsExpanded((expanded) => !expanded)}
              >
                <div className="flex items-center">{item.title && <p>{item.title}</p>}</div>

                <div>
                  <IconButton>{isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid flex-col w-full">
                  {item.children?.length && <FormGenerator form={item.children as FormItems} />}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        } else if (item.itemType === "field") {
          return <div className="flex flex-col">
            <FieldGenerator key={idx} field={item} />
          </div>;
        }
      })}
    </>
  );
};


function parseFileName(fileName: string): { jobId: string, selectedPhotoPlace: string, fieldName: string } | null {
  // Remove unnecessary escape for hyphen
  const match = /^([a-zA-Z0-9-]+)-([a-zA-Z0-9-]+)-([a-zA-Z0-9-]+)$/.exec(fileName);
  ///^([a-zA-Z0-9-]+)-([a-zA-Z0-9-]+)-([a-zA-Z0-9-]+)\.jpeg$/


  // If the match is null, return null
  if (!match) return null;

  // Return the object directly from the match
  return {
    jobId: match[1],
    selectedPhotoPlace: match[2],
    fieldName: match[3]
  };
}

const FileInputDialog = ({ name, contactId, jobId, field }: { name: string, contactId: string, jobId: string, field: string }) => {
  const [photoOpen, setPhotoOpen] = useState(false);
  const [viewPhotoOpen, setViewPhotoOpen] = useState(false);
  const { data, refetch } = useQuery<Query, QueryGetFilesArgs>(GET_FILES, {
    variables: {
      contactId,
    },
    returnPartialData: true,
    fetchPolicy: "cache-and-network",
  });

  const files = data["getFiles"];
  const jobFiles = files.filter(f => f.jobId === jobId).map(f => ({
    meta: parseFileName(f.caption),
    file: f,
  })).filter(f => !!f.meta);

  const fieldPhotos = jobFiles.filter(f => f.meta.fieldName === field);
  const invalidOptions = fieldPhotos.map(f=>f.meta.selectedPhotoPlace);
  const options = ['front', 'rear', 'side', 'other'].filter(o=>!invalidOptions.includes(o));

  return (<div className="flex flex-row gap-2 mb-4">
    {!!fieldPhotos.length && (
      <Button
        size="medium"
        type={'button'}
        variant={"contained"}
        className="mt-8"
        style={{
          backgroundColor: '#4B5563',
          color: 'white',
          fontWeight: 'bold',
          paddingRight: 10,
          paddingLeft: 10
        }}
        onClick={() => {
          if (!viewPhotoOpen) {
            setViewPhotoOpen(true)
          }
        }}
        startIcon={<CheckCircleIcon fontSize="small" />}
      >{fieldPhotos.length} Photos Added</Button>
    )}

    <Button
      size={"medium"}
      type={'button'}
      variant={"contained"}
      fullWidth={!fieldPhotos.length}
      className="mt-8"
      style={{
        color: 'white',
        fontWeight: 'bold',
        paddingRight: 10,
        paddingLeft: 10
      }}
      color={"primary"}
      startIcon={<CameraAlt fontSize="small" />}
      onClick={() => {
        if (!photoOpen) {
          setPhotoOpen(true)
        }
      }}>Add Photos</Button>

    <PhotoDialog
      contactId={contactId}
      name={name}
      fieldName={field}
      title="Upload Photo"
      open={photoOpen}
      optionsLabel={options}
      onClose={() => {
        setPhotoOpen(false);
      }}
      jobId={jobId}
    />
    <PhotoDialogView
      title={`${field.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()} Photos`}
      contactId={contactId}
      onDelete={refetch}
      files={fieldPhotos.map(f=>f.file)}
      open={viewPhotoOpen}
      onClose={() => {
        setViewPhotoOpen(false);
      }}
    />
  </div>)
}