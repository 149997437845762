import React, { useEffect } from "react";
import { useField } from "formik";

interface NumberSelectorProps {
  label?: string;
  required?: boolean;
  min?: number;
  max?: number;
  name: string;
}

const NumberSelector: React.FC<NumberSelectorProps> = ({ label, required = false, min = 0, max = 10, name }) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const { value: formikValue } = field;

  // Function to determine the color based on the number's position in the range
  const getColor = (num: number): string => {
    const third = Math.floor((max - min + 1) / 3);

    if (num <= third) return "bg-red-500"; // Red for the first 1/3
    if (num <= 2 * third) return "bg-yellow-500"; // Yellow for the second 1/3
    return "bg-green-500"; // Green for the last 1/3
  };

  // Create an array of numbers from min to max
  const numbers = Array.from({ length: max - min + 1 }, (_, i) => min + i);

  // Handle selection and trigger setValue
  const handleClick = (num: number) => {
    setValue(String(num)); // This updates the Formik field value
  };

  useEffect(() => {
    if (formikValue !== undefined) {
      // Any side-effect based on formikValue can go here if needed
    }
  }, [formikValue]); // This will trigger whenever the formikValue changes

  return (
    <div className="space-y-4">
      {/* Render the label if provided */}
      {label && (
        <label className="block text-sm font-medium">
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}

      <div className="flex flex-wrap justify-center gap-1">
        {numbers.map((num) => (
          <div
            key={num}
            className={`cursor-pointer flex items-center justify-center 
              ${getColor(num)} 
              ${formikValue == num ? "border-4 border-white scale-110" : ""} 
              transform transition-all duration-200 ease-in-out hover:scale-105
              ${
                num < 6
                  ? "w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24"
                  : "w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-10 lg:h-10 xl:w-10 xl:h-10"
              }
              max-w-full
              rounded-full`}
            onClick={() => handleClick(num)}
          >
            <span className="text-white font-semibold">{num}</span>
          </div>
        ))}
      </div>

      {/* Render error message if there's an error in validation */}
      {meta.touched && meta.error ? <div className="text-red-500 text-sm mt-1">{meta.error}</div> : null}

      {/* Hidden input to make it work with form submission */}
      <input type="hidden" name={name} value={formikValue || ""} required={required} />
    </div>
  );
};

export default NumberSelector;
